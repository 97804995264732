@import '../../App.scss';

.footer {
  margin-top: 1rem;
  padding: 1rem;
  background-color: hsl(210, 18%, 85%);
  // position: fixed;
  // bottom: 0;
  // left: 0;
	// width: 100%;
	
	img {
		max-width: 180px;
	}
}


.tile.is-ancestor:not(:last-child) {
	margin-bottom: 0;
}