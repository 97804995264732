@import '../../App.scss';

.hero {
	background-color: $primary;
	padding: 15px 0 8px 14px;
}

// .hero-body {
// 	height: 80px;
// }

.hero-img {
	width: 43%;
	max-width: 580px;
}