.colorBox {
	// width: 85px;
	height: 54px;
	background-color: white;
	// border: 1px solid black;
}

.colorBox-mobile {
	// width: 85px;
	height: 44px;
	background-color: white;
	// border: 1px solid black;
}

.colorBox-bordered {
	// width: 85px;
	height: 54px;
	background-color: white;
	border: 1px solid rgb(203, 203, 203);
}

.colorBox-modal {
	// width: 85px;
	height: 24px;
	background-color: white;
	// border: 1px solid black;
}

.mobile-test {
	border: 1px solid rgb(40, 33, 33);
}
.stickyHeader {
	margin-bottom: 15px;
}

.cb_img {
	width: 150px;
}

.liquidTableRow{
	font-weight: bold;
	color: #00AEEF;
}

.coolant-new-product{
	cursor: pointer;
}

.highlight-orange{
	color: orange
}

/* Tooltip container */
.priceTooltipTablet {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.priceTooltipTablet .priceTooltipTablettext {
  visibility: hidden;
  width: 320px;
  background-color: rgb(101, 101, 101);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
	right: 105%;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.priceTooltipTablet:hover .priceTooltipTablettext {
  visibility: visible;
}

.mobile-info-icon {
	min-width: 35px;
}