
main {
	padding-bottom: 25px;
	min-height: 100%;

	.search-overlay {
		position: fixed;
		display: block;
		width: 50%;
		height: 600px;
		top: 230px;
		// left: 0;
		right: 10px;
		bottom: 100px;
		background-color: rgba(237, 245, 255, 0.94);
		z-index: 2;
		cursor: pointer;
		padding: 10px;
		border-style: solid;
		border-color: rgba(67, 150, 252, 0.872);
		overflow: scroll;
	}
}

.method-C {
	padding-top: 400px;
	margin-top: -400px;
	background-clip: content-box;
}

.scrollable {
	overflow-y: scroll;
	height: 600px;
}

