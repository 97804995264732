@import '../../App.scss';

.navbar, .navbar-menu {
	&.top {
		background-color: $lightblue !important;


	// 	.a.navbar-item:focus,
	// 	a.navbar-item:focus-within,
	// 	// a.navbar-item{
	// 	// 	&.hover {
	// 	// 				background-color: hsl(124, 74%, 50%) !important;
	// 	// 			}
	// 	// }
	// 	a.navbar-item.is-active,
	// 	.navbar-link:focus,
	// 	.navbar-link:focus-within,
	// 	.navbar-link:hover,
	// 	.navbar-link.is-active {
	// 		&.top {
	// 			color: $darkblue !important;
	// 			background-color: red !important;
	// 			.has-text-white{ 
	// 				&.hover {
	// 					background-color: hsl(124, 74%, 50%) !important;
	// 				}
	// 			}
	// 		}
	// 	}

	}
	
}

.navbar-item,
.navbar-link {
	&.top {
		padding-left: 20px;
		padding-right: 20px;
		color: white;
	}
	&.top:hover {
		
		color: rgb(255, 255, 255) !important;
		background-color:  hsl(204, 86%, 66%);
	}
}

.nested.dropdown:hover > .dropdown-menu {
  display: block;
	background-color: rgb(130, 130, 130);
	// border: 3px solid black;
	padding: 3px;
}
.nested.dropdown .dropdown-menu {
  top: -12px;
  margin-left: 100%;
	background-color: red;
}
.nested.dropdown .dropdown-trigger button::after {

}
.nested.dropdown .dropdown-trigger button {
  padding: 0px 0px;
  border: 0px;
  font-size: 14px;
  font-weight: 400;  
}

