@charset "utf-8";

// Import a Google Font
// @import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');

$darkblue: #006fac;
$lightblue: hsl(204, 71%, 53%);
$turquoise: hsl(171, 100%, 41%);
$brown: #757763;
$beige-light:   hsl(210, 18%, 85%);
$beige-lighter: #eff0eb;

// Update Bulma's global variables
$family-sans-serif: 'Roboto', sans-serif;
$primary: $darkblue;
$link: $lightblue;
$widescreen-enabled: false;
$fullhd-enabled: false;
$grey-light: $beige-light;

$navbar-item-active-color: $darkblue;
$navbar-item-hover-background-color: hsl(202, 75%, 92%);

// Update some of Bulma's component variables
// $body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;
// $container-max-width: 1500px;
$modal-content-width: 900px;
$modal-z: 9999;

@import '../node_modules/bulma/bulma.sass'

//Import only what you need from Bulma
// @import '../node_modules/bulma/sass/utilities/_all.sass';
// @import '../node_modules/bulma/sass/base/generic.sass';
// @import '../node_modules/bulma/sass/elements/_all.sass';
// @import '../node_modules/bulma/sass/grid/_all.sass';
// @import '../node_modules/bulma/sass/helpers/_all.sass';
// @import '../node_modules/bulma/sass/form/_all.sass';
// @import '../node_modules/bulma/sass/elements/container.sass';
// @import '../node_modules/bulma/sass/elements/form.sass';
// @import '../node_modules/bulma/sass/elements/title.sass';
// @import '../node_modules/bulma/sass/components/_all.sass';
// @import '../node_modules/bulma/sass/layout/_all.sass';
// @import '../node_modules/bulma/sass/layout/section.sass';