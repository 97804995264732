.acid {
	min-width: 200px;
	// height: 275px;
	// object-fit: cover;
	// box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.engine {
	width: 350px;
	// height: 250px;
	object-fit: scale-down;
	// box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.wash {
	width: 350px;
	// height: 250px;
	object-fit: scale-down;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ob {
	width: 200px;
	height: 275px;
	object-fit: cover;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ob-section {
	margin-top: 20px !important;
}

.mossrevive-img {
	max-width: 500px;
	max-height: 333px;
	object-fit: cover;
}

.fragrance-section {
	margin: 0 10% 0 10%;

}

.cb {
	width: 375px;
	height: 180px;
	object-fit: cover;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.header-bottom-border {
	// background-color: blue;
	height: 2px;
	margin: 0;
	padding: 0;
}

.color-filter {
	line-height: 0.8;
}